// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDOot_pFQvBRMds8NKwhfwI3K5AU2R6Fvg",
  authDomain: "referral-e756d.firebaseapp.com",
  projectId: "referral-e756d",
  storageBucket: "referral-e756d.appspot.com",
  messagingSenderId: "679370403784",
  appId: "1:679370403784:web:b5b600f95e7a3076bc5afd",
  measurementId: "G-16MYGH377X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export {app, analytics}
